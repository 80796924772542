
import './VueErrorServeur.scss'

const VueErrorServeur = () => {
    return(
        <div className="error-serveur">
            <h1> Erreur Serveur <br/> Actualiser la Page </h1>
        </div>
    )
}

export default VueErrorServeur
let CarteReunionGEO = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.32083910264, -21.248870086645],
            [55.33360370237, -21.226055849948],
            [55.33729996689, -21.213257005476],
            [55.351738936114, -21.196379276943],
            [55.368474065453, -21.181721747878],
            [55.3847689852, -21.163843377703],
            [55.40552756827, -21.144527515903],
            [55.405382547531, -21.147101425978],
            [55.399692527802, -21.163806384523],
            [55.390492600127, -21.171524347242],
            [55.385957936391, -21.185881198514],
            [55.380755752582, -21.187865612756],
            [55.376363075355, -21.203983943789],
            [55.369312430721, -21.212916199654],
            [55.358364926133, -21.218476289837],
            [55.352298863532, -21.228719804712],
            [55.357591905244, -21.236700267943],
            [55.352956426867, -21.254595647228],
            [55.324550127153, -21.247113150308],
            [55.322188822409, -21.253051122837],
            [55.32083910264, -21.248870086645],
          ],
        ],
      },
      properties: { code: "97401", nom: "Les Avirons" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.700687024779, -21.005907766425],
            [55.677993349526, -21.010270692987],
            [55.671270642159, -21.017954889479],
            [55.672199059135, -21.029582328078],
            [55.660757498481, -21.034765604263],
            [55.643632503214, -21.034059800535],
            [55.618772465727, -21.042344935477],
            [55.609356726026, -21.057164566348],
            [55.597676076719, -21.069139170869],
            [55.587366778275, -21.08388944578],
            [55.575457149833, -21.082519763846],
            [55.569006707991, -21.07746546561],
            [55.552219843196, -21.073040346633],
            [55.561300854057, -21.062792326856],
            [55.561209936694, -21.049425647108],
            [55.555946467717, -21.033531784249],
            [55.561278767477, -21.020613937448],
            [55.584977104843, -21.001364364327],
            [55.591036446155, -21.002822065382],
            [55.6086442118, -20.99905624694],
            [55.624352334484, -20.990769017056],
            [55.639535393783, -20.97856353239],
            [55.651916037569, -20.978393646486],
            [55.672648957443, -20.985255948063],
            [55.689047540141, -20.979643722404],
            [55.700683666125, -20.978611757199],
            [55.700687024779, -21.005907766425],
          ],
        ],
      },
      properties: { code: "97402", nom: "Bras-Panon" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.455513013365, -21.286022005645],
            [55.46336979127, -21.262451065832],
            [55.464430755766, -21.254458433538],
            [55.459328094593, -21.234949449735],
            [55.462269164753, -21.2181622784],
            [55.462320417105, -21.195166508483],
            [55.490825958153, -21.182116873261],
            [55.495351446265, -21.177284743502],
            [55.502458818887, -21.146586263025],
            [55.506749593919, -21.139754415356],
            [55.526949856807, -21.135806783103],
            [55.52269007174, -21.142238392133],
            [55.525870783111, -21.158742788717],
            [55.531773551049, -21.175562564806],
            [55.530356085083, -21.183532929268],
            [55.516344313462, -21.185365299015],
            [55.515625436493, -21.201215845072],
            [55.510182869831, -21.20834025601],
            [55.513076527853, -21.216524512786],
            [55.511874423974, -21.226594913549],
            [55.491400039597, -21.242779229818],
            [55.479701224044, -21.254595047936],
            [55.47107771981, -21.257710210543],
            [55.470186060828, -21.269585905356],
            [55.460090428722, -21.283855385642],
            [55.455513013365, -21.286022005645],
          ],
        ],
      },
      properties: { code: "97403", nom: "Entre-Deux" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.322188822409, -21.253051122837],
            [55.324550127153, -21.247113150308],
            [55.352956426867, -21.254595647228],
            [55.357591905244, -21.236700267943],
            [55.352298863532, -21.228719804712],
            [55.358364926133, -21.218476289837],
            [55.369312430721, -21.212916199654],
            [55.376363075355, -21.203983943789],
            [55.380755752582, -21.187865612756],
            [55.385957936391, -21.185881198514],
            [55.383442682054, -21.202046587428],
            [55.391237164389, -21.212347023615],
            [55.387734521012, -21.230091879451],
            [55.392846123241, -21.25836347583],
            [55.388647630126, -21.270507616086],
            [55.380497291632, -21.281837727827],
            [55.391190136698, -21.292625919326],
            [55.367871676711, -21.284874530062],
            [55.340820614137, -21.280814644562],
            [55.332230039939, -21.273040398416],
            [55.330559136553, -21.258678397427],
            [55.322188822409, -21.253051122837],
          ],
        ],
      },
      properties: { code: "97404", nom: "Etang-Sale" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.583106681023, -21.372561699758],
            [55.571433456659, -21.375964895304],
            [55.547526506413, -21.369146515457],
            [55.536146885561, -21.363054201544],
            [55.546639494842, -21.32286121742],
            [55.571020230355, -21.313932192977],
            [55.58260609942, -21.306654568036],
            [55.593329560287, -21.292340055639],
            [55.604299010693, -21.288194726748],
            [55.603930883736, -21.299313531527],
            [55.589237404696, -21.31561532229],
            [55.586113362402, -21.328260954958],
            [55.589110841705, -21.341761059357],
            [55.58948381131, -21.357700649638],
            [55.583106681023, -21.372561699758],
          ],
        ],
      },
      properties: { code: "97405", nom: "Petite-ile" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.660715903941, -21.20315943628],
            [55.655005144652, -21.196697501067],
            [55.638239226912, -21.167597091329],
            [55.629600709103, -21.170498449449],
            [55.624771004095, -21.180478325239],
            [55.61798411671, -21.182606372457],
            [55.603730028732, -21.179862297705],
            [55.588795783537, -21.167860236647],
            [55.588402061966, -21.155351966123],
            [55.576886078952, -21.136979091306],
            [55.572696023335, -21.133730637153],
            [55.586985578621, -21.124780875024],
            [55.600515591013, -21.128062144198],
            [55.609222315415, -21.126301857956],
            [55.620092817379, -21.119068151323],
            [55.629842224049, -21.117541998269],
            [55.636635996924, -21.106409557137],
            [55.645246035364, -21.102608565305],
            [55.655980740213, -21.105804296265],
            [55.71565980074, -21.172386278284],
            [55.706421220906, -21.17574055238],
            [55.69492942399, -21.186005778808],
            [55.682841880098, -21.184620193687],
            [55.660715903941, -21.20315943628],
          ],
        ],
      },
      properties: { code: "97406", nom: "Plaine des Palmistes" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.277506140412, -20.957735641539],
            [55.283865702763, -20.940238469923],
            [55.288112554157, -20.937199184536],
            [55.289292404055, -20.923219361846],
            [55.30433191919, -20.929152184369],
            [55.330467464915, -20.927942008342],
            [55.329876530391, -20.941263279328],
            [55.319078096365, -20.969205817357],
            [55.306974111211, -20.959485101658],
            [55.285247642172, -20.956164319519],
            [55.277506140412, -20.957735641539],
          ],
        ],
      },
      properties: { code: "97407", nom: "Le Port" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.464357731566, -21.085749251769],
            [55.448623514018, -21.084029825805],
            [55.437842708093, -21.092698381522],
            [55.418834624693, -21.095807535975],
            [55.405703899079, -21.085867596629],
            [55.407535944952, -21.078778576618],
            [55.417452450055, -21.067808634294],
            [55.416227312083, -21.063360198746],
            [55.40230687695, -21.050145534415],
            [55.400921668952, -21.032713311537],
            [55.404151431867, -21.019709945259],
            [55.398476314376, -21.008089852249],
            [55.388483923216, -21.001592940433],
            [55.374597894869, -20.997217224547],
            [55.370154835274, -20.987832895342],
            [55.354458625969, -20.975468586641],
            [55.319078096365, -20.969205817357],
            [55.329876530391, -20.941263279328],
            [55.330467464915, -20.927942008342],
            [55.343016813716, -20.922804444904],
            [55.355139561644, -20.910079541904],
            [55.375863528729, -20.896094202327],
            [55.379073619095, -20.90991541857],
            [55.388652586783, -20.925003636364],
            [55.391745643379, -20.935979058912],
            [55.40716560768, -20.956669460011],
            [55.403694622206, -20.98179857228],
            [55.419412781816, -20.983318185699],
            [55.43953411936, -20.992013858698],
            [55.451961587332, -21.001345667221],
            [55.461144439045, -21.015120564276],
            [55.460662964538, -21.022662535302],
            [55.45423962415, -21.035396130713],
            [55.454259112779, -21.057929482186],
            [55.444874989554, -21.068178578398],
            [55.464357731566, -21.085749251769],
          ],
        ],
      },
      properties: { code: "97408", nom: "La Possession" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.574200164955, -20.993648300668],
            [55.585348843724, -20.980591084497],
            [55.599618578881, -20.967621030533],
            [55.60865567211, -20.964995778733],
            [55.620665982534, -20.95505149856],
            [55.629079300567, -20.955857405179],
            [55.645408616043, -20.940849953948],
            [55.645847104712, -20.935397006071],
            [55.629769065399, -20.922755967143],
            [55.628669514347, -20.910556032016],
            [55.651277361332, -20.917327142672],
            [55.664378168674, -20.925161312909],
            [55.680839253857, -20.94275458694],
            [55.694615577906, -20.963060447372],
            [55.700683666125, -20.978611757199],
            [55.689047540141, -20.979643722404],
            [55.672648957443, -20.985255948063],
            [55.651916037569, -20.978393646486],
            [55.639535393783, -20.97856353239],
            [55.624352334484, -20.990769017056],
            [55.6086442118, -20.99905624694],
            [55.591036446155, -21.002822065382],
            [55.584977104843, -21.001364364327],
            [55.574200164955, -20.993648300668],
          ],
        ],
      },
      properties: { code: "97409", nom: "Saint-Andre" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.495346258186, -21.111444597211],
            [55.502624076393, -21.100595097302],
            [55.51733507462, -21.092881672496],
            [55.526715308641, -21.080958120639],
            [55.550626397862, -21.075486545953],
            [55.552219843196, -21.073040346633],
            [55.569006707991, -21.07746546561],
            [55.575457149833, -21.082519763846],
            [55.587366778275, -21.08388944578],
            [55.597676076719, -21.069139170869],
            [55.609356726026, -21.057164566348],
            [55.618772465727, -21.042344935477],
            [55.643632503214, -21.034059800535],
            [55.660757498481, -21.034765604263],
            [55.672199059135, -21.029582328078],
            [55.671270642159, -21.017954889479],
            [55.677993349526, -21.010270692987],
            [55.700687024779, -21.005907766425],
            [55.702240950638, -21.016337520332],
            [55.707788812226, -21.028026265193],
            [55.713564045606, -21.029915684477],
            [55.728090424356, -21.046588707296],
            [55.732198197765, -21.061971681394],
            [55.7387344493, -21.071505992954],
            [55.752531093226, -21.08530072252],
            [55.762611492449, -21.100604921748],
            [55.767290097112, -21.103637376411],
            [55.75032507295, -21.119093998609],
            [55.73967187102, -21.13708376697],
            [55.74512559788, -21.145451705768],
            [55.73110587408, -21.167487311489],
            [55.71565980074, -21.172386278284],
            [55.655980740213, -21.105804296265],
            [55.645246035364, -21.102608565305],
            [55.636635996924, -21.106409557137],
            [55.629842224049, -21.117541998269],
            [55.620092817379, -21.119068151323],
            [55.609222315415, -21.126301857956],
            [55.600515591013, -21.128062144198],
            [55.586985578621, -21.124780875024],
            [55.572696023335, -21.133730637153],
            [55.556598392764, -21.138208438993],
            [55.526949856807, -21.135806783103],
            [55.506749593919, -21.139754415356],
            [55.506706490335, -21.13041007366],
            [55.502301661277, -21.118624384546],
            [55.495346258186, -21.111444597211],
          ],
        ],
      },
      properties: { code: "97410", nom: "Saint-Benoit" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.375863528729, -20.896094202327],
            [55.394036917298, -20.882986388171],
            [55.421141873335, -20.874440484519],
            [55.437381127014, -20.876567897463],
            [55.456649591996, -20.876526962723],
            [55.471366123992, -20.884562415238],
            [55.485094324598, -20.885877009889],
            [55.502079518399, -20.882341920577],
            [55.507304218581, -20.911634326555],
            [55.513068631875, -20.922363956399],
            [55.501582350011, -20.936455014075],
            [55.490805191991, -20.970606365504],
            [55.491010134145, -20.988794971872],
            [55.480142895002, -20.993355884985],
            [55.477496906162, -21.002251689156],
            [55.468887949541, -21.009928584234],
            [55.461144439045, -21.015120564276],
            [55.451961587332, -21.001345667221],
            [55.43953411936, -20.992013858698],
            [55.419412781816, -20.983318185699],
            [55.403694622206, -20.98179857228],
            [55.40716560768, -20.956669460011],
            [55.391745643379, -20.935979058912],
            [55.388652586783, -20.925003636364],
            [55.379073619095, -20.90991541857],
            [55.375863528729, -20.896094202327],
          ],
        ],
      },
      properties: { code: "97411", nom: "Saint-Denis" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.700502824682, -21.376561558306],
            [55.681553265314, -21.374733505189],
            [55.670862413982, -21.383821728153],
            [55.65919329063, -21.382840878433],
            [55.652833154059, -21.385827852772],
            [55.625637295877, -21.382826767844],
            [55.609006957416, -21.386904617463],
            [55.592889076256, -21.376238333356],
            [55.583106681023, -21.372561699758],
            [55.58948381131, -21.357700649638],
            [55.589110841705, -21.341761059357],
            [55.586113362402, -21.328260954958],
            [55.589237404696, -21.31561532229],
            [55.603930883736, -21.299313531527],
            [55.604299010693, -21.288194726748],
            [55.601877474484, -21.272527056525],
            [55.600392638207, -21.25261511847],
            [55.604790917808, -21.241320987701],
            [55.60531045475, -21.228496035389],
            [55.614157575678, -21.211510162329],
            [55.62960223942, -21.197814953875],
            [55.636821829161, -21.194466818353],
            [55.650460054299, -21.204163636411],
            [55.65411246232, -21.212188398003],
            [55.653300657432, -21.22500054847],
            [55.648666619344, -21.23735570962],
            [55.67877375445, -21.240213966101],
            [55.681307344226, -21.261931609414],
            [55.692408151303, -21.279175652056],
            [55.68749181318, -21.30392374667],
            [55.688361331369, -21.311956850645],
            [55.698124601252, -21.332446778613],
            [55.698721265277, -21.351259269157],
            [55.705476347293, -21.365830207671],
            [55.700502824682, -21.376561558306],
          ],
        ],
      },
      properties: { code: "97412", nom: "Saint-Joseph" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.32083910264, -21.248870086645],
            [55.309819984342, -21.239376141427],
            [55.294129138863, -21.230372705608],
            [55.283073606482, -21.202892371556],
            [55.286772824609, -21.18504932351],
            [55.286733275629, -21.160641411695],
            [55.274414197865, -21.150557030132],
            [55.271388344634, -21.135424821026],
            [55.314868228337, -21.120504105824],
            [55.328503830875, -21.117115547108],
            [55.351210302858, -21.114372575054],
            [55.4019316572, -21.119530310213],
            [55.415935710195, -21.122720375626],
            [55.404906387959, -21.137075851326],
            [55.40552756827, -21.144527515903],
            [55.3847689852, -21.163843377703],
            [55.368474065453, -21.181721747878],
            [55.351738936114, -21.196379276943],
            [55.33729996689, -21.213257005476],
            [55.33360370237, -21.226055849948],
            [55.32083910264, -21.248870086645],
          ],
        ],
      },
      properties: { code: "97413", nom: "Saint-Leu" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.462320417105, -21.195166508483],
            [55.462269164753, -21.2181622784],
            [55.459328094593, -21.234949449735],
            [55.464430755766, -21.254458433538],
            [55.46336979127, -21.262451065832],
            [55.455513013365, -21.286022005645],
            [55.424802110774, -21.293624013079],
            [55.403916304107, -21.303511388417],
            [55.391190136698, -21.292625919326],
            [55.380497291632, -21.281837727827],
            [55.388647630126, -21.270507616086],
            [55.392846123241, -21.25836347583],
            [55.387734521012, -21.230091879451],
            [55.391237164389, -21.212347023615],
            [55.383442682054, -21.202046587428],
            [55.385957936391, -21.185881198514],
            [55.390492600127, -21.171524347242],
            [55.399692527802, -21.163806384523],
            [55.405382547531, -21.147101425978],
            [55.413893442651, -21.165168623247],
            [55.433817447437, -21.185495968027],
            [55.444746116454, -21.190827887798],
            [55.462320417105, -21.195166508483],
          ],
        ],
      },
      properties: { code: "97414", nom: "Saint-Louis" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.25415375561, -21.109737804323],
            [55.230936180403, -21.090934707249],
            [55.221239445979, -21.078095020712],
            [55.222672586796, -21.05564753099],
            [55.217135411092, -21.036469853462],
            [55.233691556437, -21.019279719694],
            [55.244908004365, -21.019876366237],
            [55.256552904916, -21.016234104298],
            [55.274828812396, -21.003236502389],
            [55.279329074841, -20.995806682978],
            [55.282766218872, -20.978605336063],
            [55.277506140412, -20.957735641539],
            [55.285247642172, -20.956164319519],
            [55.306974111211, -20.959485101658],
            [55.319078096365, -20.969205817357],
            [55.354458625969, -20.975468586641],
            [55.370154835274, -20.987832895342],
            [55.374597894869, -20.997217224547],
            [55.388483923216, -21.001592940433],
            [55.398476314376, -21.008089852249],
            [55.404151431867, -21.019709945259],
            [55.400921668952, -21.032713311537],
            [55.40230687695, -21.050145534415],
            [55.416227312083, -21.063360198746],
            [55.417452450055, -21.067808634294],
            [55.407535944952, -21.078778576618],
            [55.405703899079, -21.085867596629],
            [55.418834624693, -21.095807535975],
            [55.437842708093, -21.092698381522],
            [55.448623514018, -21.084029825805],
            [55.464357731566, -21.085749251769],
            [55.4487931736, -21.103931249234],
            [55.425467384973, -21.116989286618],
            [55.422724203932, -21.116491349553],
            [55.401171396723, -21.101625333025],
            [55.393282066512, -21.098504860293],
            [55.380649696514, -21.098497601198],
            [55.358252869171, -21.094599565288],
            [55.334770738176, -21.094760825589],
            [55.310064961378, -21.092411829139],
            [55.282024220701, -21.100531248225],
            [55.267675532317, -21.107038556224],
            [55.25415375561, -21.109737804323],
          ],
        ],
      },
      properties: { code: "97415", nom: "Saint-Paul" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.455513013365, -21.286022005645],
            [55.460090428722, -21.283855385642],
            [55.470186060828, -21.269585905356],
            [55.47107771981, -21.257710210543],
            [55.479701224044, -21.254595047936],
            [55.493285601357, -21.269022666045],
            [55.503307971939, -21.283313793251],
            [55.516848572827, -21.292118029727],
            [55.51872969409, -21.300516402976],
            [55.532515587611, -21.313699094567],
            [55.550344596555, -21.31017088992],
            [55.55540123515, -21.303801729053],
            [55.571878017618, -21.296997631472],
            [55.579194772871, -21.286585475602],
            [55.601877474484, -21.272527056525],
            [55.604299010693, -21.288194726748],
            [55.593329560287, -21.292340055639],
            [55.58260609942, -21.306654568036],
            [55.571020230355, -21.313932192977],
            [55.546639494842, -21.32286121742],
            [55.536146885561, -21.363054201544],
            [55.527168599735, -21.361948612277],
            [55.501002992462, -21.350598321592],
            [55.488681490983, -21.350477057176],
            [55.459522850383, -21.341144046724],
            [55.454781045738, -21.331585289691],
            [55.448947363585, -21.328377283819],
            [55.429383738704, -21.326204793362],
            [55.417433869019, -21.320149166714],
            [55.403916304107, -21.303511388417],
            [55.424802110774, -21.293624013079],
            [55.455513013365, -21.286022005645],
          ],
        ],
      },
      properties: { code: "97416", nom: "Saint-Pierre" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.67877375445, -21.240213966101],
            [55.804273350623, -21.252305476083],
            [55.802020994801, -21.257819143117],
            [55.803216924008, -21.276421214979],
            [55.808280901341, -21.287305928881],
            [55.804504092923, -21.312482750851],
            [55.807295563557, -21.336761171788],
            [55.799849509215, -21.346518885478],
            [55.7736071095, -21.365231547111],
            [55.760772415062, -21.363569944643],
            [55.741310273026, -21.369204880064],
            [55.727080508255, -21.369853677722],
            [55.714436310239, -21.375237068416],
            [55.700502824682, -21.376561558306],
            [55.705476347293, -21.365830207671],
            [55.698721265277, -21.351259269157],
            [55.698124601252, -21.332446778613],
            [55.688361331369, -21.311956850645],
            [55.68749181318, -21.30392374667],
            [55.692408151303, -21.279175652056],
            [55.681307344226, -21.261931609414],
            [55.67877375445, -21.240213966101],
          ],
        ],
      },
      properties: { code: "97417", nom: "Saint-Philippe" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.540688134457, -21.004274974906],
            [55.516162357004, -21.006990624681],
            [55.507745246543, -21.000083290332],
            [55.498175535234, -21.001258370256],
            [55.468887949541, -21.009928584234],
            [55.477496906162, -21.002251689156],
            [55.480142895002, -20.993355884985],
            [55.491010134145, -20.988794971872],
            [55.490805191991, -20.970606365504],
            [55.501582350011, -20.936455014075],
            [55.513068631875, -20.922363956399],
            [55.507304218581, -20.911634326555],
            [55.502079518399, -20.882341920577],
            [55.541560144133, -20.894673470407],
            [55.55717638151, -20.891552105995],
            [55.575391270132, -20.89474922728],
            [55.572247462609, -20.922512278951],
            [55.576813410332, -20.941353000019],
            [55.567204525963, -20.951518468337],
            [55.548893419918, -20.990295358325],
            [55.540688134457, -21.004274974906],
          ],
        ],
      },
      properties: { code: "97418", nom: "Sainte-Marie" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.67877375445, -21.240213966101],
            [55.648666619344, -21.23735570962],
            [55.653300657432, -21.22500054847],
            [55.65411246232, -21.212188398003],
            [55.660715903941, -21.20315943628],
            [55.682841880098, -21.184620193687],
            [55.69492942399, -21.186005778808],
            [55.706421220906, -21.17574055238],
            [55.71565980074, -21.172386278284],
            [55.73110587408, -21.167487311489],
            [55.74512559788, -21.145451705768],
            [55.73967187102, -21.13708376697],
            [55.75032507295, -21.119093998609],
            [55.767290097112, -21.103637376411],
            [55.780072832563, -21.121889982214],
            [55.800058131227, -21.127272443589],
            [55.808258463549, -21.136108721776],
            [55.826736952168, -21.145764202902],
            [55.834386208899, -21.160249915049],
            [55.832909277473, -21.16658165693],
            [55.836628265464, -21.183406142408],
            [55.825382333317, -21.187945665986],
            [55.824708367899, -21.203251849258],
            [55.814058919803, -21.214293733916],
            [55.808520861285, -21.227530149161],
            [55.804273350623, -21.252305476083],
            [55.67877375445, -21.240213966101],
          ],
        ],
      },
      properties: { code: "97419", nom: "Sainte-Rose" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.574200164955, -20.993648300668],
            [55.554875237691, -21.006211765365],
            [55.540688134457, -21.004274974906],
            [55.548893419918, -20.990295358325],
            [55.567204525963, -20.951518468337],
            [55.576813410332, -20.941353000019],
            [55.572247462609, -20.922512278951],
            [55.575391270132, -20.89474922728],
            [55.592451795185, -20.895958362665],
            [55.61789051779, -20.90935709824],
            [55.628669514347, -20.910556032016],
            [55.629769065399, -20.922755967143],
            [55.645847104712, -20.935397006071],
            [55.645408616043, -20.940849953948],
            [55.629079300567, -20.955857405179],
            [55.620665982534, -20.95505149856],
            [55.60865567211, -20.964995778733],
            [55.599618578881, -20.967621030533],
            [55.585348843724, -20.980591084497],
            [55.574200164955, -20.993648300668],
          ],
        ],
      },
      properties: { code: "97420", nom: "Sainte-Suzanne" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.574200164955, -20.993648300668],
            [55.584977104843, -21.001364364327],
            [55.561278767477, -21.020613937448],
            [55.555946467717, -21.033531784249],
            [55.561209936694, -21.049425647108],
            [55.561300854057, -21.062792326856],
            [55.552219843196, -21.073040346633],
            [55.550626397862, -21.075486545953],
            [55.526715308641, -21.080958120639],
            [55.51733507462, -21.092881672496],
            [55.502624076393, -21.100595097302],
            [55.495346258186, -21.111444597211],
            [55.478076134323, -21.093119286411],
            [55.464357731566, -21.085749251769],
            [55.444874989554, -21.068178578398],
            [55.454259112779, -21.057929482186],
            [55.45423962415, -21.035396130713],
            [55.460662964538, -21.022662535302],
            [55.461144439045, -21.015120564276],
            [55.468887949541, -21.009928584234],
            [55.498175535234, -21.001258370256],
            [55.507745246543, -21.000083290332],
            [55.516162357004, -21.006990624681],
            [55.540688134457, -21.004274974906],
            [55.554875237691, -21.006211765365],
            [55.574200164955, -20.993648300668],
          ],
        ],
      },
      properties: { code: "97421", nom: "Salazie" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.660715903941, -21.20315943628],
            [55.65411246232, -21.212188398003],
            [55.650460054299, -21.204163636411],
            [55.636821829161, -21.194466818353],
            [55.62960223942, -21.197814953875],
            [55.614157575678, -21.211510162329],
            [55.60531045475, -21.228496035389],
            [55.604790917808, -21.241320987701],
            [55.600392638207, -21.25261511847],
            [55.601877474484, -21.272527056525],
            [55.579194772871, -21.286585475602],
            [55.571878017618, -21.296997631472],
            [55.55540123515, -21.303801729053],
            [55.550344596555, -21.31017088992],
            [55.532515587611, -21.313699094567],
            [55.51872969409, -21.300516402976],
            [55.516848572827, -21.292118029727],
            [55.503307971939, -21.283313793251],
            [55.493285601357, -21.269022666045],
            [55.479701224044, -21.254595047936],
            [55.491400039597, -21.242779229818],
            [55.511874423974, -21.226594913549],
            [55.513076527853, -21.216524512786],
            [55.510182869831, -21.20834025601],
            [55.515625436493, -21.201215845072],
            [55.516344313462, -21.185365299015],
            [55.530356085083, -21.183532929268],
            [55.531773551049, -21.175562564806],
            [55.525870783111, -21.158742788717],
            [55.52269007174, -21.142238392133],
            [55.526949856807, -21.135806783103],
            [55.556598392764, -21.138208438993],
            [55.572696023335, -21.133730637153],
            [55.576886078952, -21.136979091306],
            [55.588402061966, -21.155351966123],
            [55.588795783537, -21.167860236647],
            [55.603730028732, -21.179862297705],
            [55.61798411671, -21.182606372457],
            [55.624771004095, -21.180478325239],
            [55.629600709103, -21.170498449449],
            [55.638239226912, -21.167597091329],
            [55.655005144652, -21.196697501067],
            [55.660715903941, -21.20315943628],
          ],
        ],
      },
      properties: { code: "97422", nom: "Le Tampon" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.25415375561, -21.109737804323],
            [55.267675532317, -21.107038556224],
            [55.282024220701, -21.100531248225],
            [55.310064961378, -21.092411829139],
            [55.334770738176, -21.094760825589],
            [55.358252869171, -21.094599565288],
            [55.380649696514, -21.098497601198],
            [55.393282066512, -21.098504860293],
            [55.401171396723, -21.101625333025],
            [55.422724203932, -21.116491349553],
            [55.415935710195, -21.122720375626],
            [55.4019316572, -21.119530310213],
            [55.351210302858, -21.114372575054],
            [55.328503830875, -21.117115547108],
            [55.314868228337, -21.120504105824],
            [55.271388344634, -21.135424821026],
            [55.256062863898, -21.116709703931],
            [55.25415375561, -21.109737804323],
          ],
        ],
      },
      properties: { code: "97423", nom: "Les Trois-Bassins" },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.462320417105, -21.195166508483],
            [55.444746116454, -21.190827887798],
            [55.433817447437, -21.185495968027],
            [55.413893442651, -21.165168623247],
            [55.405382547531, -21.147101425978],
            [55.40552756827, -21.144527515903],
            [55.404906387959, -21.137075851326],
            [55.415935710195, -21.122720375626],
            [55.422724203932, -21.116491349553],
            [55.425467384973, -21.116989286618],
            [55.4487931736, -21.103931249234],
            [55.464357731566, -21.085749251769],
            [55.478076134323, -21.093119286411],
            [55.495346258186, -21.111444597211],
            [55.502301661277, -21.118624384546],
            [55.506706490335, -21.13041007366],
            [55.506749593919, -21.139754415356],
            [55.502458818887, -21.146586263025],
            [55.495351446265, -21.177284743502],
            [55.490825958153, -21.182116873261],
            [55.462320417105, -21.195166508483],
          ],
        ],
      },
      properties: { code: "97424", nom: "Cilaos" },
    },
  ],
};

for (let i = 0; i < CarteReunionGEO["features"].length; i++) {
  for (
    let j = 0;
    j < CarteReunionGEO["features"][i]["geometry"]["coordinates"][0].length;
    j++
  ) {
    let oneValue =
      CarteReunionGEO["features"][i]["geometry"]["coordinates"][0][j][0];
    CarteReunionGEO["features"][i]["geometry"]["coordinates"][0][j][0] =
      CarteReunionGEO["features"][i]["geometry"]["coordinates"][0][j][1];
    CarteReunionGEO["features"][i]["geometry"]["coordinates"][0][j][1] =
      oneValue;
  }
}

const carteReunionPointSeul = {};
for (let i = 0; i < CarteReunionGEO["features"].length; i++) {
  carteReunionPointSeul[CarteReunionGEO["features"][i]["properties"]["nom"]] =
    [];
  for (
    let j = 0;
    j < CarteReunionGEO["features"][i]["geometry"]["coordinates"][0].length;
    j++
  ) {
    carteReunionPointSeul[
      CarteReunionGEO["features"][i]["properties"]["nom"]
    ].push([
      CarteReunionGEO["features"][i]["geometry"]["coordinates"][0][j][0],
      CarteReunionGEO["features"][i]["geometry"]["coordinates"][0][j][1],
    ]);
  }
}

export { carteReunionPointSeul };
